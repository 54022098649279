import React from 'react';


function NotFound() {
  return (
    <div className="Company">
      <div className="main-content columns">
        <div className="center column is-three-fifths is-offset-one-fifth hero">
              <div className="column is-12">
                <img src="/images/404.svg" alt=""></img>
              </div>
              <div className="column is-12">
                <b>Empty page! Looks like you took wrong turn!</b>
              </div>
        </div>
      </div>

    </div>
  );
}

export default NotFound;
