import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    logoWrapper: {
        paddingTop: theme.spacing(7),
        textAlign: "center", 
    },
    logo: {
        height: theme.spacing(10),
        width: theme.spacing(10),
    },
    logoTitle: {
        fontSize: theme.spacing(2),
        fontWeight: "bold",
        paddingBottom: theme.spacing(7),
        textAlign: "center", 
    },
    center: {
        textAlign: "center",
    }
}));



export default function AuthWrapper(props) {
    const { children } = props;
    const classes = useStyles();
    console.log("asdads");
    return (
        <React.Fragment>
            <CssBaseline />
            <Grid
                container
                direction="row"
                alignItems="center"
                className={classes.root}
            >
                <Grid item xs={12} className={classes.logoWrapper}>
                    <img src="/sendontime.png" className={classes.logo}/>
                </Grid>
                <Grid item xs={12} className={classes.logoTitle}>
                    <span >
                        sendontime
                    </span>
                </Grid>
                <Grid item xs={12} className={classes.center}>
                    {children}
                </Grid>
            </Grid>


        </React.Fragment>
    )
}
