import React, { useContext, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Button from '@material-ui/core/Button';
import CreateEmailServiceProvider from '../Component/CreateEmailServiceProviderDialog';
import CreateSmsServiceProvider from '../Component/CreateSmsServiceProviderDialog';

const SERVICE_PROVIDERS = loader('../Utils/Queries/GET_SERVICE_PROVIDERS.graphql');
const GET_STRIPE_PORTAL_SESSION = loader('../Utils/Queries/GET_STRIPE_PORTAL_SESSION.graphql');
const GET_ME = loader('../Utils/Queries/GET_ME.graphql');

const Settings = () => {
    const [dialog, activeDialog] = useState();
    const [subnav, activateSubnav] = useState("email_providers");
    const DIALOG = {
        createEmailServiceProvider: "CreateEmailServiceProvider",
        createSmsServiceProvider: "CreateSmsServiceProvider"
    };
    const subnavItems = [
        {id: "email_providers", name: "Email Providers", dialog: DIALOG.createEmailServiceProvider},
        {id: "sms_providers", name: "SMS Providers", dialog: DIALOG.createSmsServiceProvider},
        {id: "billing", name: "Billing", dialog: null},
        {id: "api_limits", name: "Api Key", dialog: null}
    ];

    const { loading, error, data: service_providers } = useQuery(SERVICE_PROVIDERS);
    const [ getStripePortalSession ] = useLazyQuery(GET_STRIPE_PORTAL_SESSION, { 
        fetchPolicy: "network-only", onCompleted: ({portal}) => window.open(portal.url, "_blank") 
    });
    const { loading: loadingLimit, error: errorLimit, data: dataLimit } = useQuery(GET_ME);

    return (
        <>
            <div className="site-maincontent-subheader"></div>
            <div className="site-maincontent-wide-content">


                <div className="site-maincontent-header">
                    <h1 className="site-maincontent-header-title">{subnavItems.find(item => item.id === subnav).name}</h1>
                    <div className="site-maincontent-header-action">
                        { subnavItems.find(item => item.id === subnav).dialog ? <Button onClick={() => activeDialog(subnavItems.find(item => item.id === subnav).dialog)} style={{backgroundColor: "white"}} variant="contained">create</Button> : null }
                    </div>
                </div>

                <div className="site-maincontent-container-split-by3">

                    <div className="site-maincontent-container-split-part1">
                        <div className="site-maincontent-container-subnav">
                            {
                                subnavItems.filter(item => item.id !== "billing").map((item, index) => (
                                    <div key={"subnav_" + index} className={`site-maincontent-container-subnav-item subnav-widget ${item.id === subnav ? "subnav-widget--ACTIVE" : ""}`} onClick={() => activateSubnav(item.id)}>
                                        <h4>{item.name}</h4>
                                    </div>
                                ))
                            }
                            <div key={"subnav_billing"} className={`site-maincontent-container-subnav-item subnav-widget ${subnav === "billing" ? "subnav-widget--ACTIVE" : ""}`} onClick={() => { getStripePortalSession(); activateSubnav("billing");}}>
                                <h4>Billing</h4>
                            </div>
                        </div>
                    </div>


                    <div className="site-maincontent-container-split-part2">
                        
                        {
                            subnav === "email_providers" ? 
                            loading ? <p>Loading...</p> : 
                            error ? <p>error :(</p> : (
                            <table className="site-maincontent-item">
                                <thead>
                                    <tr>
                                        <th>Provider Id</th>
                                        <th>Provider</th>
                                        <th>Identifier</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        service_providers.getServiceProviders.filter(item => item.type==="email").map((service_provider, key) => (
                                            <tr align="center" key={service_provider.id}>
                                                <td data-label="Id">{service_provider.id}</td>
                                                <td data-label="Provider">{service_provider.email_provider}</td>
                                                <td data-label="Identifier">{`*********${service_provider.config_identifier}`}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table> ) : null
                        }
                        {
                            subnav === "sms_providers" ? 
                            loading ? <p>Loading...</p> : 
                            error ? <p>error :(</p> : (
                            <table className="site-maincontent-item">
                                <thead>
                                    <tr>
                                        <th>Provider Id</th>
                                        <th>Provider</th>
                                        <th>Identifier</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        service_providers.getServiceProviders.filter(item => item.type==="sms").map((service_provider, key) => (
                                            <tr align="center" key={service_provider.id}>
                                                <td data-label="Id">{service_provider.id}</td>
                                                <td data-label="Provider">{service_provider.sms_provider}</td>
                                                <td data-label="Identifier">{`*********${service_provider.config_identifier}`}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table> ) : null

                        }
                        {
                            subnav === "api_limits" ? 
                            loadingLimit ? <p>Loading...</p> : 
                            errorLimit ? <p>error :(</p> : (
                            <table className="site-maincontent-item">
                                <thead>
                                    <tr>
                                        <th>Api Key</th>
                                        <th>Total reminders per month</th>
                                        <th>Number of open reminders</th>
                                        <th>Emails per reminder</th>
                                        <th>Sms per reminder</th>
                                        <th>Webhook per reminder</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (dataLimit && dataLimit.me )? 
                                            <tr align="center" key="api_limit">
                                                <td data-label="Key">{dataLimit.me.key}</td>
                                                <td data-label="Total reminders per month">{dataLimit.me.limit.api_limit}</td>
                                                <td data-label="Number of open reminders">{dataLimit.me.limit.active_reminder_limit}</td>
                                                <td data-label="Emails per reminder">{dataLimit.me.limit.per_reminder_email_limit}</td>
                                                <td data-label="Sms per reminder">{dataLimit.me.limit.per_reminder_sms_limit}</td>
                                                <td data-label="Webhook per reminder">{dataLimit.me.limit.per_reminder_webhook_limit}</td>
                                            </tr> : null
                                       
                                    }
                                </tbody>
                            </table> ) : null
                        }
                        {
                            subnav === "billing" ?  <div>Billing will open in a new tab</div> : null
                        }
                    </div>

                    <div className="site-maincontent-container-split-part3">
                        
                    </div>

                </div>


                <CreateEmailServiceProvider open={dialog === DIALOG.createEmailServiceProvider} handleClose={() => activeDialog(null)} />
                <CreateSmsServiceProvider open={dialog === DIALOG.createSmsServiceProvider} handleClose={() => activeDialog(null)} />
                
            </div>
        </>
    );
}

export default Settings;