import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import  formConfig from '../Form/Config';
import FormBuilder from '../Form/FormBuilder';

const useStyles = makeStyles((theme) => ({
  root: {
    outline: "none"
  },
  content: {
    outline: "none"
  },
  subtitle: {
    paddingLeft: theme.spacing(3),
  },
  paraheading: {
    paddingLeft: theme.spacing(3),
    // fontSize: theme.spacing(2),
  },
}));

export default function CreateSmsServiceProviderDialog(props) {

  const classes = useStyles();
  const { open, handleClose } = props;
  const scroll = 'paper';

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullScreen={false}
      className={classes.root}
    >
      <DialogTitle id="scroll-dialog-title">Create SMS Providers</DialogTitle>
      <DialogContent className={classes.content} dividers={scroll === 'paper'}

        id="scroll-dialog-description"
        ref={descriptionElementRef}
        tabIndex={-1}
      >
        <FormBuilder config={formConfig.CreateSmsServiceProvider} onCompleted={handleClose}/>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
          </Button>
      </DialogActions>
    </Dialog>
  );
}