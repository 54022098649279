import React, { useState } from "react";
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useForm } from "react-hook-form";
import { makeStyles, Button, Grid, Radio, RadioGroup, Paper, TextField, FormLabel, FormControl, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  form: {
    width: "80%"
  },
  paper: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  input: {
    width: "80%"
  },
  radioGroup: {
    display: "inline"
  },
  radio: {
    color: theme.palette.text.secondary
  },
  submit: {
    textAlign: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
}));

function Options(props) {
  const { register, classes, errors, active } = props;
  const { id, name, type, validation, options } = props.config;
  console.log(active);

  return (
    <>
      <RadioGroup name={id} className={classes.radioGroup}>
        {
          options.map((item, index) => (
            <FormControlLabel key={id+index} value={item.value} label={item.name} inputRef={register(validation)} control={<Radio />} />
          ))
        }
      </RadioGroup>
      {
        options.map((item, key) => {
          return item.fields && active === item.value ? item.fields.map((subfield, subkey) => (
            <Grid item xs={12} key={key+subkey} className={classes.paper}>
              <TextField key={key} className={classes.input} error={!!errors[subfield.id]} label={subfield.name} name={subfield.id} inputRef={register(subfield.validation)} />
            </Grid>
          )) : null
        })
      }
    </>
  )

}

export default function FormBuilder({ config, onCompleted }) {
  const classes = useStyles();
  const { register, handleSubmit, errors, getValues, watch } = useForm();
  const [mutationFn, { loading, error }] = useMutation(config.mutation, { onCompleted });

  const onSubmit = payload => {
    console.log(payload);
    mutationFn({ variables: {payload} });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center" justify="center">

        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Grid container spacing={3} alignItems="center">
            {
              config.fields.map((fd, key) => {
                return fd.type === "radio" ? (
                  <Grid item xs={12} key={key} className={classes.paper}>
                    <Options key={key} active={watch(fd.id)} classes={classes} errors={errors} className={classes.radio} config={fd} register={register} />
                  </Grid>
                ) : (
                    <Grid item xs={12} key={key} className={classes.paper}>
                      <TextField key={key} className={classes.input} error={!!errors[fd.id]} label={fd.name} name={fd.id} inputRef={register(fd.validation)} />
                    </Grid>
                  )
              })
            }
            <Grid item xs={12} className={classes.submit}>
              <Button variant="contained" color="secondary" type="submit">Submit</Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  );
}