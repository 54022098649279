import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import Amplify, { Auth } from "aws-amplify";
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AmplifySignIn, AmplifySignUp, AmplifyConfirmSignIn, AmplifyConfirmSignUp, AmplifyForgotPassword, AmplifyRequireNewPassword, AmplifyVerifyContact, AmplifyGreetings, AmplifySignOut, AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { AnimatedSwitch } from 'react-router-transition';

import AuthWrapper from './Auth/AuthWrapper';
import Layout from './Layout/Layout';
import NotFound from './Page/404';
import Logs from './Page/Logs';
import Dashboard from './Page/Dashboard';
import Settings from './Page/Settings';
import Api from './Page/Api';
import awsExports from "./aws-exports";

import './App.css';

Amplify.configure(awsExports);

const getApolloClient = () => new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
  connectToDevTools: true,
  typeDefs: {},
  request: async (operation) => {
    const session = await Auth.currentSession();
    console.log(session);
    operation.setContext({
      headers: {
        Authorization: session && session.idToken && session.idToken.jwtToken ? `Bearer ${session.idToken.jwtToken}` : ''
      }
    });
    return;
  },
});

const App = () => {
  const [authState, setAuthState] = useState();

  const apolloClient = getApolloClient();

  useEffect(() => {
    return onAuthUIStateChange(newAuthState => {
      setAuthState(newAuthState)
    });
  }, []);



  const navLinks = [
    { id: "dashboard", path: "/", component: <Dashboard />, label: "Dashboard"},
    { id: "logs", path: "/logs", component: <Logs />, label: "Logs" },
    { id: "settings", path: "/settings", component: <Settings />, label: "Settings" }
  ];

  return (

    <AmplifyAuthenticator usernameAlias="email">
      <div slot="sign-in"><AuthWrapper><AmplifySignIn usernameAlias="email" /></AuthWrapper></div>
      <div slot="sign-up"><AuthWrapper><AmplifySignUp usernameAlias="email" /></AuthWrapper></div>
      <div slot="confirm-sign-in"><AuthWrapper><AmplifyConfirmSignIn usernameAlias="email" /></AuthWrapper></div>
      <div slot="confirm-sign-up"><AuthWrapper><AmplifyConfirmSignUp usernameAlias="email" /></AuthWrapper></div>
      <div slot="forgot-password"><AuthWrapper><AmplifyForgotPassword usernameAlias="email" /></AuthWrapper></div>
      <div slot="require-new-password"><AuthWrapper><AmplifyRequireNewPassword usernameAlias="email" /></AuthWrapper></div>
      <div slot="verify-contact"><AuthWrapper><AmplifyVerifyContact usernameAlias="email" /></AuthWrapper></div>
      <ApolloProvider client={apolloClient}>
          <Router>
            <AnimatedSwitch
                atEnter={{ opacity: 0 }}
                atLeave={{ opacity: 0 }}
                atActive={{ opacity: 1 }}
                className="site-global-container"
              >
                {
                  navLinks.map(link => (
                    <Route key={link.id} exact path={link.path}>
                      <Layout navLinks={navLinks} activeLink={link} signoutButton={<AmplifySignOut />}>
                          {link.component}
                      </Layout>
                    </Route>
                  ))
                }
                <Route>
                  <NotFound />
                </Route>
            </AnimatedSwitch>
          </Router>
      </ApolloProvider>
    </AmplifyAuthenticator>
  );
}

export default App;
