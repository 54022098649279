import { loader } from 'graphql.macro';

const SET_EMAIL_SERVICE_PROVIDER = loader('../Utils/Mutation/SET_EMAIL_SERVICE_PROVIDER.graphql');
const SET_REMINDER = loader('../Utils/Mutation/SET_REMINDER.graphql');
const SET_SMS_SERVICE_PROVIDER = loader('../Utils/Mutation/SET_SMS_SERVICE_PROVIDER.graphql');
const GET_REMINDER_LOGS = loader('../Utils/Queries/GET_REMINDER_LOGS.graphql');
const GET_REMINDERS = loader('../Utils/Queries/GET_REMINDERS.graphql');
const GET_SERVICE_PROVIDERS = loader('../Utils/Queries/GET_SERVICE_PROVIDERS.graphql');

export default {
    CreateEmailServiceProvider: {
        mutation: SET_EMAIL_SERVICE_PROVIDER,
        fields: [
            { 
                id: "provider", name: "Email Providers", type: "radio", validation: {required: true}, error: "Email provider is required", options: [
                {
                    name: "Sendgrid", value: "sendgrid", fields: [
                        { id: "sendgridConfig.key", name: "Key", type: "text", placeholder: "Key", validation: {required: true}, error: "Key is required"}
                    ]
                }, {
                    name: "SES", value: "ses", fields: [
                        { id: "sesConfig.key", name: "Key", type: "text", placeholder: "Key", validation: {required: true}, error: "Key is required"},
                        { id: "sesConfig.secret", name: "Secret", type: "text", placeholder: "Secret", validation: {required: true}, error: "Secret is required"},
                        { id: "sesConfig.region", name: "Region", type: "text", placeholder: "Region", validation: {required: true}, error: "Region is required"}
                    ]
                }]
            }
        ]
    },
    CreateSmsServiceProvider: {
        mutation: SET_SMS_SERVICE_PROVIDER,
        fields: [
            { 
                id: "provider", name: "SMS Providers", type: "radio", validation: {required: true}, error: "SMS provider is required", options: [
                {
                    name: "Twilio", value: "twilio", fields: [
                        {id: "twilioConfig.authToken", name: "Auth Token", type: "text", placeholder: "Auth Token", validation: {required: true}, error: "Auth token is required"},
                        {id: "twilioConfig.accountSid", name: "Account Sid", type: "text", placeholder: "Account Sid", validation: {required: true}, error: "Account sid is required"}
                    ]
                }, {
                    name: "SNS", value: "sns", fields: [
                        { id: "snsConfig.key", name: "Key", type: "text", placeholder: "Key", validation: {required: true}, error: "Key is required"},
                        { id: "snsConfig.secret", name: "Secret", type: "text", placeholder: "Secret", validation: {required: true}, error: "Secret is required"},
                        { id: "snsConfig.region", name: "Region", type: "text", placeholder: "Region", validation: {required: true}, error: "Region is required"}
                    ]
                }]
            }
        ]
    },
}