import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import History from '@material-ui/icons/History';
import MailOutline from '@material-ui/icons/MailOutline';
import SmsOutlined from '@material-ui/icons/SmsOutlined';
import Pagination from '@material-ui/lab/Pagination';
import Email from '@material-ui/icons/Email';
import Forum from '@material-ui/icons/Forum';
import Http from '@material-ui/icons/Http';

import ReminderLogs from '../Component/ReminderLogs';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(6)
    },
    pageHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4)
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(6),
        right: theme.spacing(6)
    },
    listWrapper: {
        overflow: "visible",
        backgroundColor: "white",
        borderRadius: theme.spacing(0.25),
        boxShadow: "8px 8px 16px -16px pink",
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        transition: "all .2s ease-in-out"
        // "&:hover": {
        //     transform: "scale(1.01)"
        // },
    },
    listItemWrapper: {
        overflow: "visible",
        backgroundColor: "white",
        borderRadius: theme.spacing(0.25),
        boxShadow: "8px 8px 16px -16px pink",
        margin: theme.spacing(0.25),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(1.5),
    },
    listItemHeader: {
        overflow: "visible",
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
        boxShadow: "8px 8px 16px -16px grey",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3),
    },
    listItemContent: {
        fontSize: "small",
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5)

    },
    listItemSubContent: {
        fontSize: "x-small",
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5)
    },
    listItem: {
        overflow: "visible",
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
        // boxShadow: "8px 8px 16px -16px grey",
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        // paddingRight: theme.spacing(2),
        transition: "all .2s ease-in-out",
        "&:hover": {
            transform: "scale(1.01)"
        },
        "&:selected": {
            borderColor: "orange",
            borderWidth: theme.spacing(0.25),
            borderStyle: "solid",
        }
    },
    snippet: {
        fontSize: theme.spacing(1.5),
        // textAlign: "center"
    },
    snippetIcon: {
        fontSize: theme.spacing(2.5),
        color: "lightgrey",
        paddingTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    pagination: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4)
    }
}));

const REMINDERS = loader('../Utils/Queries/GET_REMINDERS.graphql');
const STATS = loader('../Utils/Queries/GET_STATS.graphql');

const Logs = props => {
    const size = 10;
    const classes = useStyles();
    const [offset, updateOffset] = useState(0);
    const [open, handleOpen] = useState(false);
    const [activeReminder, setActiveReminder] = useState();
    const { loading, error, data: reminders } = useQuery(REMINDERS, { variables: { size, offset } });
    const { data: stats } = useQuery(STATS);

    return (
        <>
            <div className="site-maincontent-subheader"></div>
            <div className="site-maincontent-wide-content">
                <div className="site-maincontent-header">
                    <h1 className="site-maincontent-header-title">Logs</h1>
                </div>


                <div className="site-maincontent-container-split-by1">
                    <div className="site-maincontent-container-split-part">
                        <table className="site-maincontent-item">
                            <thead>
                                <tr>
                                    <th>Due Date</th>
                                    <th>Triggers</th>
                                    <th>Reminder Id</th>
                                    <th>Logs</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ? <p>Loading...</p> : 
                                    error ? <p>Error :(</p> : 
                                    reminders.getReminders.map((reminder, key) => (
                                        <tr align="center" key={key}>
                                            <td data-label="Due Date">{(new Date(reminder.due)).toLocaleDateString() + " " + (new Date(reminder.due)).toLocaleTimeString()}</td>
                                            <td data-label="Triggers">
                                                <Email style={reminder.email ? { color: "#003040" } : { color: "#bdbdbd" }} className={classes.listItemContent}/>
                                                <Forum style={reminder.sms ? { color: "#003040" } : { color: "#bdbdbd" }} className={classes.listItemContent}/>
                                                <Http style={reminder.webhook ? { color: "#003040" } : { color: "#bdbdbd" }} className={classes.listItemContent}/>
                                            </td>
                                            <td data-label="Reminder Id">{reminder.id}</td>
                                            <td data-label="Logs">
                                                <IconButton aria-label="logs" color="secondary" onClick={() => {
                                                    setActiveReminder(reminder.id);
                                                    handleOpen(true);
                                                }}>
                                                    <History />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                        <div className="site-maincontent-panel h400">
                            <div className="site-maincontent-row">
                                {
                                    loading ? <p>Loading...</p> : 
                                    error ? <p>Error :(</p> : 
                                    <Grid container spacing={2} justify="center" className={classes.pagination}>
                                        {
                                            stats && stats.stats ? <Pagination count={Math.ceil(stats.stats.total_reminders / size)} color="secondary" page={offset === 0 ? 1 : (offset / size + 1)} onChange={(_, page) => updateOffset((page - 1) * size)} /> : null
                                        }
                                    </Grid>
                                    
                                }
                                <ReminderLogs open={open} handleClose={() => handleOpen(false)} title="Reminder Logs" reminder_id={activeReminder} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Logs;